import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import { RouteComponentProps } from '@reach/router'
import { Typography } from '@mui/material'
import Button from '../components/Button'
import { useLazyQuery } from '@apollo/client'
import { prepareLogin, isLoggedIn, setUser } from '../utils/auth'
import { LOGIN } from '../graphql'

export type Credentials = {
  username: string
  password: string
}

const LoginPage: React.FC<RouteComponentProps> = () => {
  const [credentials, setCredentials] = useState<Credentials>({
    username: '',
    password: '',
  })

  const [errors, setErrors] = useState({
    username: '',
    password: '',
    graphqlError: '',
  })

  const [login, { data }] = useLazyQuery(LOGIN, {
    onError: (error) => {
      const errorMessage =
        error.message === 'Failed to fetch'
          ? 'Bitte kontaktieren Sie den Administrator.'
          : 'Benutzername oder Passwort falsch'
      setErrors((prevErrors) => ({
        ...prevErrors,
        graphqlError: errorMessage,
      }))
    },
    onCompleted: () => {
      setUser()
      isLoggedIn()
      setErrors({ username: '', password: '', graphqlError: '' })
    },
  })

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/app')
    }
  }, [data])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setCredentials((prevCredentials) => ({ ...prevCredentials, [name]: value }))
  }

  useEffect(() => {
    if (credentials.username) {
      setErrors((prevErrors) => ({ ...prevErrors, username: '' }))
    }
    if (credentials.password) {
      setErrors((prevErrors) => ({ ...prevErrors, password: '' }))
    }
  }, [credentials])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let errors = {
      username: '',
      password: '',
      graphqlError: '',
    }
    Object.keys(credentials).map((key) => {
      if (!credentials[key as keyof Credentials]) {
        const errorMessage =
          key === 'username' ? 'Bitte geben Sie einen Benutzernamen ein' : 'Bitte geben Sie ein Passwort ein'

        errors = { ...errors, [key]: errorMessage }
      }
    })
    setErrors(errors)

    const isValid = Object.values(errors).every((error) => !error)
    if (isValid) {
      prepareLogin(credentials)
      login()
    }
  }

  return (
    <StyledLoginContainer>
      <StyledHeadline>
        <Typography variant="h2">{'< Zeit am Draht >'}</Typography>
      </StyledHeadline>
      <form onSubmit={onSubmit}>
        <StyledInputContainer>
          <StyledInput
            placeholder="Benutzer"
            name="username"
            id="username"
            value={credentials.username}
            onChange={handleInputChange}
            style={errors.username ? { border: '2px solid var(--error)' } : {}}
          />
          <StyledErrorLabel>{errors.username}</StyledErrorLabel>
        </StyledInputContainer>
        <StyledInputContainer>
          <StyledInput
            placeholder="Passwort"
            name="password"
            id="password"
            type="password"
            value={credentials.password}
            onChange={handleInputChange}
            style={errors.password ? { border: '2px solid var(--error)' } : {}}
            autoComplete="off"
          />
          <StyledErrorLabel>{errors.password}</StyledErrorLabel>
          <StyledErrorLabel>{errors.graphqlError}</StyledErrorLabel>
        </StyledInputContainer>
        <StyledButtonContainer>
          <Button type="submit" style={{ backgroundColor: 'var(--primary)', minWidth: '100px' }}>
            Login
          </Button>
        </StyledButtonContainer>
      </form>
    </StyledLoginContainer>
  )
}

export default LoginPage

const StyledLoginContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledHeadline = styled.div`
  margin-bottom: 46px;
`
const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
`
const StyledInput = styled.input`
  outline-color: var(--primary);
  border: 2px solid var(--primary);
  border-radius: 4px;
  min-width: 350px;
  max-width: 550px;
  height: 23px;
  padding: 8.5px 14px;
`
const StyledErrorLabel = styled.span`
  color: var(--error);
  margin: 4px 14px 0;
  position: absolute;
  bottom: -24px;
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`
